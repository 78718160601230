import React from "react"
import Footer from "../components/footer"
import ContentBlock from "../components/contentblock"

export default function Contact() {
  return (

    <main>
          <h1>Contact Rachel</h1>


        <ContentBlock
          id="contact">
          <p>Currently booked up and unavailable for any large projects until end of August 2022, however I can still help with any small builds, migrations, support, or strategies. </p>
          <p>Say <a href="mailto:hello@rachel.codes">hello@rachel.codes</a></p>
         </ContentBlock>

         <Footer icon_credits="Icon credits: Night Mode by Ravindra Kalkani from the Noun Project" />

    </main>
  );
}
